<template>
  <v-container grid-list-md>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>My Submission History</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>
  </v-container>
</template>
<script>
import { ref } from '@vue/composition-api'

export default {
  setup (props, { root }) {
    const formTitle = ref('')

    return {
      formTitle
    }
  }
}
</script>
